let controls = document.querySelectorAll('.questions__control');
let current = document.querySelector('.questions__question--active');

controls.forEach((ele) => {
  ele.addEventListener('click', changeSlide)
  ele.addEventListener('click', () => {
    clearInterval(autoPlay);
  });
})

function changeSlide(event, autoId = null) {
  let slideCtrlID;

  if(autoId == null) {
    slideCtrlID = event.currentTarget.dataset.slidectrl;
  } else {
    slideCtrlID = autoId;
  }

  let newSlide = document.querySelector(`[data-slideid="${slideCtrlID}"]`);
  let currentSlideCtrl = document.querySelector(`[data-slidectrl="${current.dataset.slideid}"]`);
  let newSlideCtrl = document.querySelector(`[data-slidectrl="${slideCtrlID}"]`);

  currentSlideCtrl.classList.remove('questions__control--active');
  newSlideCtrl.classList.add('questions__control--active');

  current.classList.remove('questions__question--active');
  newSlide.classList.add('questions__question--active');
  current = newSlide;
}

// Auto Looping.
let index = 1;
const autoPlay = setInterval(() => {
  if(index >= controls.length) {
    index = 1;
  } else {
    index++;
  }

  changeSlide(null, index);
}, 6000)